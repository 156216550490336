import { useState, useEffect } from "react";
import axios from "axios";
function AdminPanel({token, logged}) {
	const [countries, setCountries] = useState([])
	const [nations, setNations] = useState([])
	const [users, setUsers] = useState([])
	const [rolePlayers, setRolePlayers] = useState([])
	const [newCountryValue, setNewCountryValue] = useState("")
	const [newNationValue, setNewNationValue] = useState("")
	const [newCountryBool, setNewCountryBool] = useState(false)
	const [newNationBool, setNewNationBool] = useState(false)
	useEffect( () => {
	  axios.get("https://api.possiball.app/api/nations", {
		headers: {
		  // 'application/json' is the modern content-type for JSON, but some
		  // older servers may use 'text/json'.
		  // See: http://bit.ly/text-json
		  'content-type': 'application/json',
		  'Authorization': 'Bearer ' + token
		}})
		.then((result) => {
		  setNations(result.data['hydra:member'])
		})
		.catch(() => {
			logged(false)
		  })
	  axios.get("https://api.possiball.app/api/countries", {
		headers: {
		  // 'application/json' is the modern content-type for JSON, but some
		  // older servers may use 'text/json'.
		  // See: http://bit.ly/text-json
		  'content-type': 'application/json',
		  'Authorization': 'Bearer ' + token
		}})
		.then((result) => {
			console.log(result.data['hydra:member'])
		  setCountries(result.data['hydra:member'])
		})
		.catch(() => {
			logged(false)
		  })
	  axios.get("https://api.possiball.app/api/player_types", {
		headers: {
			  // 'application/json' is the modern content-type for JSON, but some
			  // older servers may use 'text/json'.
			  // See: http://bit.ly/text-json
		  'content-type': 'application/json',
		  'Authorization': 'Bearer ' + token
		}})
		.then((result) => {
		  setRolePlayers(result.data['hydra:member'])
		})
		.catch(() => {
			logged(false)
		  })
		axios.get("https://api.possiball.app/api/users", {
		  headers: {
				// 'application/json' is the modern content-type for JSON, but some
				// older servers may use 'text/json'.
				// See: http://bit.ly/text-json
			'content-type': 'application/json',
			'Authorization': 'Bearer ' + token
		  }})
		  .then((result) => {
			setUsers(result.data['hydra:member'])
		  })
		  .catch(() => {
			logged(false)
		  })
	}, [])
	return (
	  <>
	  	<h1>Nazioni</h1>
		<button onClick={() => setNewNationBool(!newNationBool)}>Aggiungi nuova</button>
		{newNationBool && <form>
		  <input value={newNationValue} onChange={e => setNewNationValue(e.target.value)}/>
		  <button type="button" onClick={() => axios.post("https://api.possiball.app/api/nations", {name: newNationValue}, {
			headers: {
			  'content-type': 'application/json',
		      'Authorization': 'Bearer ' + token
			}})
			  .then(() => alert("Inserimento riuscito"))
			  .catch(() => alert("Errore durante l'inserimento'"))
			}>Invia</button>
		</form>}
		<table>
  		  <tr>
    	    <th>#</th>
    		<th>Nome</th>
    		<th>Operazioni</th>
  		  </tr>
		  {nations.map((singleNation, index) => <tr>
		  	<td>{index}</td>
			<td>{singleNation.name}</td>
			<td><button  type="button" onClick={async () => { if (await window.confirm("Vuoi davvero cancellare?")) axios.delete("https://api.possiball.app" + singleNation['@id'], {headers: {
		  	    'content-type': 'application/json',
		  	    'Authorization': 'Bearer ' + token
			  }})
			  .then(() => alert("Cancellazione riuscita"))
			  .catch(() => alert("Errore durante la cancellazione")) }}>Cancella</button></td>
  		  </tr>)}
  		</table>
		<h1>Province</h1>
		<button  type="button" onClick={() => setNewCountryBool(!newCountryBool)}>Aggiungi nuova</button>
		{newCountryBool && <form>
		  <input value={newCountryValue} onChange={e => setNewCountryValue(e.target.value)}/>
		  <button type="button" onClick={() => { const data = {name: newNationValue}; axios.post("https://api.possiball.app/api/countries", data, {
			headers: {
			  'content-type': 'application/json',
		      'Authorization': 'Bearer ' + token
			}})
			  .then(() => alert("Inserimento riuscito"))
			  .catch(() => alert("Errore durante l'inserimento'"))
			}}>Invia</button>
		</form>}
		<table>
  		  <tr>
    	    <th>#</th>
    		<th>Nome</th>
    		<th>Operazioni</th>
  		  </tr>
		  {countries.map((singleCountry, index) => <tr>
			  <td>{index}</td>
			  <td>{singleCountry.name}</td>
			  <td><button type="button" onClick={async () => { if (await window.confirm("Vuoi davvero cancellare?")) axios.delete("https://api.possiball.app" + singleCountry['@id'], {headers: {
		  	      'content-type': 'application/json',
		  	      'Authorization': 'Bearer ' + token
			  	}})
			    .then(() => alert("Cancellazione riuscita"))
			    .catch(() => alert("Errore durante la cancellazione")) }}>Cancella</button></td>
  		  	</tr>)}
  		</table>
		<h1>Utenti</h1>
		<table>
  		  <tr>
    	    <th>#</th>
    		<th>Nome</th>
    		<th>Nickname</th>
    		<th>Email</th>
    		<th>Operazioni</th>
  		  </tr>
		  {users.map((singleUser, index) => <tr>
			  <td>{index}</td>
			  <td>{singleUser.fullName}</td>
			  <td>{singleUser.nickname}</td>
			  <td>{singleUser.email}</td>
			  <td><button  type="button" onClick={async () => { if (await window.confirm("Vuoi davvero cancellare?")) axios.delete("https://api.possiball.app" + singleUser['@id'], {headers: {
		  	      'content-type': 'application/json',
		  	      'Authorization': 'Bearer ' + token
			  	}})
			    .then(() => alert("Cancellazione riuscita"))
			    .catch(() => alert("Errore durante la cancellazione")) }}>Cancella</button></td>
  		  	</tr>)}
  		</table>
		<h1>Ruolo</h1>
		<table>
  		  <tr>
    	    <th>#</th>
    		<th>Nome</th>
    	  </tr>
		  {rolePlayers.map((singleRole, index) => <tr>
			  <td>{index}</td>
			  <td>{singleRole.name}</td>
			  <td><button  type="button" onClick={async () => { if (await window.confirm("Vuoi davvero cancellare?")) axios.delete("https://api.possiball.app" + singleRole['@id'], {headers: {
		  	      'content-type': 'application/json',
		  	      'Authorization': 'Bearer ' + token
			  	}})
			    .then(() => alert("Cancellazione riuscita"))
			    .catch(() => alert("Errore durante la cancellazione")) }}>Cancella</button></td>
  		  	</tr>)}
  		</table>
	  </>
	);
  }
  
  export default AdminPanel;