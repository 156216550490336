import React, {useState} from "react";
import AdminPanel from "./AdminPanel";
import LoginForm from "./LoginForm";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [token, setToken] = useState(null)
  let content;
    if (isLoggedIn) {
      content = <AdminPanel token={token} logged={setIsLoggedIn}/>;
    } else {
      content = <LoginForm token={setToken} logged={setIsLoggedIn}/>;
  }
  return (
    <div>
      {content}
    </div>
  );
}

export default App;
