import axios from "axios";
import React, {useState} from "react";
function LoginForm({token, logged}) {
	console.log(token)
	const [userName,setUserName] = useState('')
	const [password,setPassword] = useState('')
	const login = () => {
	  const data = {}
	  data.email = userName
	  data.password = password
	  axios.post("https://api.possiball.app/auth", data)
		.then((result) => {
		  token(result.data.payload.token)
		  logged(true)
		  /*props.token(result.data.payload.token)
		  props.logged(true)*/
		})
	}
	return (
	  <div>
		<h1>Log In</h1>
      	<form>
          <label>
            <p>Username</p>
          	<input type="text" onChange={e => setUserName(e.target.value)}/>
          </label>
          <label>
            <p>Password</p>
          	<input type="password" onChange={e => setPassword(e.target.value)}/>
          </label>
          <div>
          	<button type="button" onClick={() => login()}>Submit</button>
          </div>
      	</form>
	  </div>
	);
  }
  
  export default LoginForm;